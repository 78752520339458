import { Icon } from '@iconify/react';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import ExportOptions from 'src/components/shared/ExportOptions';
import { ItemInterface } from 'src/components/shared/MiniDashboardSingleCard';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import Switcher from 'src/components/shared/Switcher';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';

export const generateDate = (number: number = 0) =>
  moment(new Date()).add(number, 'days').toISOString().substring(0, 10);

const Reservations: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();

  const globalCards: ItemInterface[] = [
    {
      icon: 'ph:fire-bold',
      color: '',
      title: t('today-reservations'),
      key: 'today',
      value: 0
    },

    {
      icon: 'ph:fire-bold',
      color: '',
      title: t('upcoming-reservations'),
      key: 'upcoming',
      value: 0
    },
    {
      icon: 'ph:fire-bold',
      color: '',
      title: t('opened-reservations'),
      key: 'opened',
      value: 0
    },
    {
      icon: 'uil:file-check-alt',
      color: '',
      title: t('completed-reservations'),
      key: 'completed',
      value: 0
    },
    {
      icon: 'pajamas:issue-close',
      color: '',
      title: t('cancelled-reservations'),
      key: 'cancelled',
      value: 0
    },
    {
      icon: 'solar:close-square-linear',
      color: '',
      title: t('closed-reservations'),
      key: 'invoiced',
      value: 0
    },
    {
      icon: 'uil:invoice',
      color: '',
      title: t('reservations'),
      key: 'all',
      value: 0
    }
  ];

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [responses, setResponses] = React.useState<any[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const [cards, setCards] = React.useState<any[]>(globalCards);

  const [params, setParams] = useSearchParams({ page: '1', invoice_type: 'booking' });

  const [summary, setSummary] = useState<any>({});

  const [pagination, setPagination] = React.useState<any>({
    page: 1
  });

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()]).finally(() => {
        setIsLoading(false);
      });
      rerender = false;
    }
  }, [params]);

  function GetItems() {
    setIsLoading(true);
    setIsUpdating(true);

    return prepareRequest(
      {
        url: 'bookings',
        params
      },
      (data) => {
        const { data: items, pagination: responsePaginate } = data?.result?.bookings;
        const summary = data?.result?.summary;
        const mapCards = cards.map((e) => {
          const item = summary[e.key];

          return {
            ...e,
            value: [CurrencyFormatter(item?.total || 0), '(' + (item?.count || 0) + ')'].join(' ')
          };
        });
        setCards(mapCards);
        setSummary(data?.result?.summary);
        setPagination((values: any) => responsePaginate);

        setResponses(items);
      }
    ).finally(() => {
      setIsLoading(false);
      setIsUpdating(false);
    });
  }

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>#</th>
              <th>{t('client')}</th>
              <th>{t('cashier')}</th>

              <th>{t('payment-status')}</th>
              <th>{t('status')}</th>

              <th>{t('booking-type')}</th>
              <th>{t('booking-time')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link
                        className="text-blue-600"
                        to={
                          ['unreal', 'closed', 'cancelled'].includes(item.status)
                            ? '#'
                            : '/bookings/' + item.id
                        }
                      >
                        {item.id || '-'}
                      </Link>
                    </td>
                    <td>{item?.client?.name || '-'}</td>
                    <td>{item?.cashier?.name || '-'}</td>

                    <td>
                      <span
                        className={[
                          'btn-with-icon outline-btn !rounded-full !text-xs max-w-fit',
                          item.payment_status === 'paid'
                            ? '!bg-teal-500 !text-white !border-teal-600'
                            : item.payment_status === 'partial-paid'
                            ? '!bg-yellow-500 !text-gray-800 !border-yellow-600'
                            : '!bg-gray-200 !text-gray-600'
                        ].join(' ')}
                      >
                        {t(item.payment_status)}
                      </span>
                    </td>

                    <td>
                      {/* <StatusTracking statuses={item.statuses} />  */}
                      {t('statuses.' + item.status)}
                    </td>

                    <td>{item?.from_website ? t('from-website') : t('by-admin')}</td>

                    <td>
                      {moment(item.booking_time).format('hh:mmA')}
                      <br />
                      {moment(item.booking_time).format('DD-MM-YYYY')}
                    </td>

                    <td>
                      <div className="inline-flex gap-2">
                        {['unreal', 'closed', 'cancelled'].includes(item.status) ? null : (
                          <Link
                            to={'/bookings/' + item.id}
                            className="btn-with-icon bg-blue-600 !text-white !text-xs"
                          >
                            <Icon
                              icon="iconamoon:eye"
                              width="16"
                            />
                            <span>{t('view-details')}</span>
                          </Link>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <AddNewBooking />
              {['admin', 'owner'].includes(user.user_type) ? (
                <ExportOptions excelPathname="bookings/export_excel" />
              ) : null}
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setParams((param) => {
              param.set('search_key', (e.target as any).value);

              param.set('page', '1');
              return param;
            });
            // GetItems();
          }
        }}
        onNextClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page + 1 : 1).toString()
            );

            return param;
          });
          // GetItems();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page - 1 : 1).toString()
            );

            return param;
          });
          // GetItems();
        }}
        loading={isLoading || isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <div className="p-6 space-y-4">
      <div className="flex items-center gap-6  flex-wrap">
        <div className="flex items-center gap-3">
          <div>
            <Switcher
              checked={(params.get('has_notes') as string) == '1'}
              onChange={(checked) => {
                setParams((param) => {
                  param.set('has_notes', checked ? '1' : '0');

                  return param;
                });
              }}
            />
          </div>
          <p className="form-label">{t('has-notes')}</p>
        </div>

        <div className="flex items-center gap-3">
          <div>
            <Switcher
              checked={(params.get('status') as string) == 'incompleted'}
              onChange={(checked) => {
                setParams((param) => {
                  param.set('status', checked ? 'incompleted' : 'completed');

                  return param;
                });
              }}
            />
          </div>
          <p className="form-label">{t('booking-not-complete')}</p>
        </div>
      </div>
      <div>{MEMO_TABLE}</div>
    </div>
  );
};

function AddNewBooking() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [clients, setClients] = useState<any[]>([]);
  const [marketers, setMarketers] = useState<any[]>([]);

  useEffect(() => {
    getClients();
    getMarketers();
  }, []);

  function getClients(q?: string) {
    prepareRequest(
      { url: 'users', params: { page: 1, user_type: 'client', is_active: 1, search_key: q } },
      (data) => {
        const result = data?.result?.users?.data || [];
        const mapData = result.map((client: any) => ({
          id: client.id,
          name: `${client.name} (${client.mobile})`
        }));
        setClients(() => mapData);
      }
    );
  }

  function getMarketers(q?: string) {
    prepareRequest(
      { url: 'users', params: { page: 1, user_type: 'marketer', is_active: 1, search_key: q } },
      (data) => {
        const result = data?.result?.users?.data || [];
        const mapData = result.map((marketer: any) => ({
          id: marketer.id,
          name: `${marketer.name} (${marketer.mobile})`
        }));
        setMarketers(() => mapData);
      }
    );
  }

  const {
    formik: { values, handleChange, setFieldValue, handleSubmit }
  } = useForm({
    initialValues: {},
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'bookings/new',
          method: 'post',
          data: {
            ...values,
            booking_time: values.booking_time
              ? moment(values.booking_time).format('yyyy-MM-DD HH:mm')
              : undefined
          }
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          formikHelpers.resetForm();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <button
        type="button"
        className="btn-with-icon !text-gray-600"
        onClick={() => setVisible(true)}
      >
        <span>{t('add-new')}</span>
      </button>

      <Modal
        visible={visible}
        title={t('add-new')}
        handleClose={() => setVisible(false)}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('client')}</p>
            <Select
              options={clients}
              value={values.client_id}
              onSelect={(value) => setFieldValue('client_id', value)}
              optionTxt="name"
              optionValue="id"
              onSearchChange={(ev) => getClients(ev.target.value)}
            />
            <p className="form-error">{errors['client_id']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('call-center')}</p>
            <Select
              options={marketers}
              value={values.marketer_id}
              onSelect={(value) => setFieldValue('marketer_id', value)}
              optionTxt="name"
              optionValue="id"
              onSearchChange={(ev) => getClients(ev.target.value)}
            />
            <p className="form-error">{errors['marketer_id']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('booking-time')}</p>
            <input
              type="datetime-local"
              name="booking_time"
              id="booking_time"
              className="form-input form-outline"
              value={values.booking_time}
              onChange={handleChange}
              min={new Date().toString()}
            />
            <p className="form-error">{errors['booking_time']}</p>
          </div>
          <button
            type="submit"
            className="btn-with-icon bg-primary"
            disabled={disabled}
          >
            <span>{t('submit')}</span>
          </button>
        </form>
      </Modal>
    </>
  );
}

export default Reservations;

