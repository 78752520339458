import { ReactNode } from 'react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
export default function AuthWrapper({ children }: { children?: ReactNode }) {
  return (
    <div className="flex-1 py-8 px-6">
      <div className="xl:container">
        <div className="flex items-center gap-6">
          <div className={`w-full`}>{children}</div>
        </div>
      </div>
    </div>
  );
}

