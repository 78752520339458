import { RouteObject } from 'react-router-dom';
import {
  AddCenter,
  AddProduct,
  AddService,
  BookingDetails,
  Calendar,
  Cashiers,
  Categories,
  // Packages,
  Centers,
  ChartsSummaryReports,
  Checkout,
  Clients,
  ClientsReports,
  Employees,
  ErrorPage,
  Home,
  InvoiceDetails,
  Invoices,
  Login,
  Management,
  Marketers,
  NewPos,
  NewPurchaseOrder,
  NewStocktaking,
  Offers,
  POS,
  PackagesPlans,
  Processing,
  Products,
  Profile,
  PurchaseOrderDetails,
  PurchaseOrders,
  Reports,
  ReservationItems,
  Services,
  SessionsReports,
  Settings,
  Stocktaking,
  Tutorials,
  UpdateCenter,
  UpdateProduct,
  UpdateService,
  Users,
  Wastages,
  WastagesManagement,
  ForgotPassword,
  Reservations
} from 'src/screens';
import TutorialDetails from 'src/screens/tutorials/id';
type Route = RouteObject & {
  permission?: string | string[];
  breadcrumb?: any;
};

const globalRoutes: Route[] = [
  {
    path: '*',
    element: <ErrorPage />
  },
  {
    path: '/packages',
    element: <PackagesPlans />
  },
  {
    path: '/tutorials',
    element: <Tutorials />
  },
  {
    path: '/tutorials/:id',
    element: <TutorialDetails />
  }
];

const authenticationRoutes: Route[] = [
  {
    path: '/account/login',
    element: <Login />
  },
  {
    path: '/account/forgot-password',
    element: <ForgotPassword />
  },
  ...globalRoutes
];

const routes: Route[] = [
  {
    path: '/',
    element: <Home />,
    permission: 'dashboard'
  },

  {
    path: '/invoices',
    element: <Invoices />,
    permission: 'invoices'
  },
  {
    path: '/invoices/:id',
    element: <InvoiceDetails />,
    permission: 'invoices'
  },
  {
    path: '/bookings',
    element: <Reservations />,
    permission: 'bookings'
  },
  {
    path: '/items',
    element: <ReservationItems />,
    permission: 'booking-items'
  },

  {
    path: '/bookings/:id',
    element: <BookingDetails />,
    permission: 'bookings'
  },
  {
    path: '/settings',
    element: <Settings />,
    permission: 'settings'
  },

  {
    path: '/settings/cashiers',
    element: <Cashiers />,
    permission: 'cashiers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/call-centers',
    element: <Marketers />,
    permission: 'marketers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/experts',
    element: <Employees />,
    permission: 'employees',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/clients',
    element: <Clients />,
    permission: 'clients',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/centers',
    element: <Centers />,
    permission: 'centers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/centers/add',
    element: <AddCenter />,
    permission: 'centers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/centers/:id',
    element: <UpdateCenter />,
    permission: 'centers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/management',
    element: <Management />,
    permission: 'admins',
    breadcrumb: 'settings'
  },

  {
    path: '/account/profile',
    element: <Profile />,
    permission: 'profile'
  },
  {
    path: '/settings/categories',
    element: <Categories />,
    permission: 'categories',
    breadcrumb: 'settings'
  },

  {
    path: '/settings/employees',
    element: <Users />,
    permission: 'users'
  },
  {
    path: '/settings/services',
    element: <Services />,
    permission: 'services',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/services/add',
    element: <AddService />,
    permission: 'services',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/services/:id',
    element: <UpdateService />,
    permission: 'services',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/products',
    element: <Products />,
    permission: 'products',
    breadcrumb: 'settings'
  },
  {
    path: '/products',
    element: <Products />,
    permission: 'products',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/products/add',
    element: <AddProduct />,
    permission: 'products',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/products/:id',
    element: <UpdateProduct />,
    permission: 'products',
    breadcrumb: 'settings'
  },
  // {
  //   path: '/settings/inventory',
  //   element: <Products />,
  //   permission: 'inventory',
  //   breadcrumb: 'settings'
  // },
  {
    path: '/settings/inventory/stocktaking',
    element: <Stocktaking />,
    permission: 'inventory',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/inventory/stocktaking/new',
    element: <NewStocktaking />,
    permission: 'inventory',
    breadcrumb: 'stocktaking'
  },
  {
    path: '/settings/inventory/purchase-orders',
    element: <PurchaseOrders />,
    permission: 'products',
    breadcrumb: 'inventory'
  },
  {
    path: '/settings/inventory/purchase-orders/new',
    element: <NewPurchaseOrder />,
    permission: 'products',
    breadcrumb: 'purchase-orders'
  },
  {
    path: '/settings/wastages',
    element: <Wastages />,
    permission: 'wastages',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/wastages-management',
    element: <WastagesManagement />,
    permission: 'wastages-management',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/processing',
    element: <Processing />,
    permission: 'processing',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/inventory/purchase-orders/:id',
    element: <PurchaseOrderDetails />,
    permission: 'products',
    breadcrumb: 'purchase-orders'
  },

  {
    path: '/settings/packages-offers',
    element: <Offers />,
    permission: 'packages-offers',
    breadcrumb: 'settings'
  },
  {
    path: '/calendar',
    element: <Calendar />,
    permission: 'calendar'
  },
  {
    path: '/pos',
    element: <POS />,
    permission: 'pos'
  },
  {
    path: '/pos/new',
    element: <NewPos />,
    permission: 'pos'
  },
  {
    path: '/reports',
    element: <Reports />,
    permission: 'reports'
  },
  {
    path: '/reports/charts',
    element: <ChartsSummaryReports />,
    permission: 'reports'
  },
  // {
  //   path: '/reports/incomes',
  //   element: <IncomesReports />,
  //   permission: 'reports'
  // },
  {
    path: '/reports/sessions',
    element: <SessionsReports />,
    permission: 'reports'
  },
  {
    path: '/reports/clients',
    element: <ClientsReports />,
    permission: 'reports'
  },
  {
    path: '/checkout',
    element: <Checkout />
  },

  ...globalRoutes
];

const names = [
  'dashboard',
  'centers',
  'center_owners',
  'invoices',
  'offers',
  'reports',
  'messages',
  'notifications',
  'users',
  'admins',
  'categories',
  'roles',
  'sub_categories',
  'items',
  'features',
  'email_templates',
  'email_notifications',
  'regions',
  'cities',
  'districts',
  'settings'
];

export { authenticationRoutes, globalRoutes, names, routes };