import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteObject, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from 'src/helper/AxiosInstance';
import { authenticationRoutes } from 'src/helper/routes';
import { saveUserInfo, storeToken } from 'src/reducers/auth';

function useAuth(): boolean {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const stored_token = localStorage.getItem('@token');
      const includes = ['login', 'register'];
      const regex = new RegExp('(' + includes.join('|') + ')', 'gi');

      if (!stored_token) {
        dispatch(storeToken(null));
        setIsLoading(false);
        if (!regex.test(pathname))
          return navigate('/account/login', {
            replace: true
          });
        return;
      }

      await GetUserInfo(stored_token);
    })();
  }, []);

  async function GetUserInfo(token?: string) {
    try {
      setIsLoading(true);

      const [{ data }, { data: settings }] = await Promise.all([
        axiosInstance.get('profile'),
        axiosInstance.get('settings')
      ]);

      dispatch(storeToken(token));
      dispatch(
        saveUserInfo({
          ...data?.result.profile,
          summary: data?.result.summary,
          subscription: { ...data?.result.subscription, ...data?.result.subscription_usage },
          ...data?.result.permissions,
          global_settings: settings?.result || {}
        })
      );

      // if (!data?.result.summary?.centers_count || !data?.result.summary?.subdomain)
      //   return navigate('/account/setup', { replace: true });

      if (authenticationRoutes.some((route: RouteObject) => [route.path].includes(pathname))) {
        return navigate('/', {
          replace: true
        });
      }

      navigate(pathname + search, {
        replace: true
      });
    } catch (error) {
      dispatch(storeToken(null));
      localStorage.removeItem('@token');
      navigate('/account/login', {
        replace: true
      });
    } finally {
      setIsLoading(false);
    }
  }

  return isLoading;
}

export default useAuth;

