import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type DateType = {
  from: string;
  to: string;
};

export const defaultFilterDate = {
  from: new Date().toISOString().substring(0, 10),
  to: new Date().toISOString().substring(0, 10)
};

export default function ReportsWrapper({
  children,
  onChange,
  hasFilter = true,
  isHomePage = false
}: {
  children: ReactNode;
  onChange?: (date: DateType) => any;
  hasFilter?: boolean;
  isHomePage?: boolean;
}) {
  const { t } = useTranslation();
  const [date, setDate] = useState<DateType>(defaultFilterDate);

  useEffect(() => {
    onChange?.(date);
  }, []);

  const onDateChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = ev.target;
    const result = {
      ...date,
      [name]: value
    };
    setDate((e: DateType) => result);
    onChange?.(result);
  };

  const tabs = [
    {
      name: 'summary',
      path: '/reports'
    },
    {
      name: 'sessions-summary',
      path: '/reports/sessions'
    },
    {
      name: 'clients',
      path: '/reports/clients'
    }
  ];

  return (
    <div className="p-6 space-y-4">
      {!isHomePage && (
        <div className="grid justify-center">
          <ul className="overflow-x-auto flex items-center whitespace-nowrap gap-2">
            {tabs.map((tab: any, index: number) => (
              <li key={index}>
                <SingleTab item={tab} />
              </li>
            ))}
          </ul>
        </div>
      )}

      {hasFilter ? (
        <div className="grid grid-wrapper gap-4">
          <div className="form-group">
            <p className="form-label">{t('from')}</p>
            <input
              type="date"
              name="from"
              id="from-date"
              defaultValue={date.from}
              className="form-input form-outline"
              onChange={onDateChange}
            />
          </div>
          <div className="form-group">
            <p className="form-label">{t('to')}</p>
            <input
              type="date"
              name="to"
              id="to-date"
              min={date.from}
              defaultValue={date.to}
              className="form-input form-outline"
              onChange={onDateChange}
            />
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
}

function SingleTab({ item }: any) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isSelected = useMemo(() => pathname == item.path, [item]);

  return (
    <Link
      to={item.path}
      className={[
        'btn-with-icon transition-all',
        isSelected ? 'bg-primary' : '!text-gray-600'
      ].join(' ')}
    >
      <span>{t(item.name)}</span>
    </Link>
  );
}

