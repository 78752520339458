import { Icon } from '@iconify/react';
import { pick } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Delete from 'src/components/shared/Delete';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import AddForm from 'src/components/shared/users/AddForm';
import UpdateForm from 'src/components/shared/users/UpdateForm';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { RootState } from 'src/store';

export default function Employees() {
  let rerender: boolean = true;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [responses, setResponses] = React.useState<any[]>([]);
  const { center } = useSelector((state: RootState) => state.globalResponse);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: '',
    gender: ''
  });

  React.useEffect(() => {
    if (rerender) {
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, [
        'page',
        'is_active',
        'search_key',
        'user_type',
        'gender'
      ]);

      const { data } = await axiosInstance.get('users', {
        params: {
          is_active: is_active,
          ...paginate,
          user_type: 'employee',
          center_id: center
        }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.users;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>Name</th>
              <th>Salary</th>
              <th>Target</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Latest update</th>
              <th>Actions </th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.name || '-'}</td>
                    <td>{CurrencyFormatter(item.salary || 0)}</td>
                    <td>{CurrencyFormatter(item.target_amount || 0)}</td>
                    <td>{item.email || '-'}</td>
                    <td>{item.mobile || '-'}</td>

                    <td>
                      <ChangeStatus
                        pathname={'users/update_is_active/' + item.id}
                        refetch={GetItems}
                        active={!!item.is_active}
                      />
                    </td>

                    <td>
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td>
                      <div className="inline-flex gap-2">
                        <button
                          className="edit-btn"
                          onClick={() => {
                            setEditItem(item);
                            setEditVisible(true);
                          }}
                        >
                          Edit
                        </button>
                        <Delete
                          pathname={'users/delete/' + item.id}
                          refetch={GetItems}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <button
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                onClick={() => setVisible(true)}
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>New employee</span>
              </button>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title="New employee"
      >
        <AddForm
          closeModal={setVisible}
          reFetching={GetItems}
          user_type="employee"
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title="Update employee"
      >
        <UpdateForm
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={editItem}
          user_type="employee"
        />
      </Modal>
    </React.Fragment>
  );
}

