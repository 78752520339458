import AuthWrapper from 'src/components/account/AuthWrapper';
import EmailLogin from 'src/components/account/login/EmailLogin';

const Login = () => {
  return (
    <AuthWrapper>
      <EmailLogin />
    </AuthWrapper>
  );
};

export default Login;

