import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import useLogout from 'src/hooks/useLogout';
import usePermissions from 'src/hooks/usePermissions';
import { RootState } from 'src/store';
import { MoveToTop } from '../animations';

interface RouterInterface {
  title: string;
  path: string;
  icon: string;
}

const routes: RouterInterface[] = [
  {
    title: 'home',
    icon: 'ri:home-5-line',
    path: '/'
  },

  {
    title: 'pos',
    icon: 'fluent:scan-table-20-filled',
    path: '/pos'
  },
  {
    title: 'reservations',
    icon: 'uil:invoice',
    path: '/bookings'
  },
  {
    title: 'services',
    icon: 'solar:pin-list-bold',
    path: '/items'
  },
  {
    title: 'products',
    icon: 'fluent-mdl2:product-variant',
    path: '/products'
  },

  {
    icon: 'tabler:file-invoice',
    title: 'invoices',
    path: '/invoices'
  },
  {
    title: 'reports',
    icon: 'solar:pie-chart-2-bold-duotone',
    path: '/reports'
  },
  {
    title: 'settings',
    icon: 'ri:settings-line',
    path: '/settings'
  }
];

const SideBar: FC = () => {
  const location = useLocation();
  const menuContainer = useRef<HTMLElement | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { handleLogged } = useLogout();
  const { t } = useTranslation();

  const { filterRoutes } = usePermissions();

  const _filterRoutes = useMemo(() => {
    return routes.filter((route) => {
      return filterRoutes.some((ev: any) => ev.path === route.path);
      // return route;
    });
  }, [filterRoutes]);

  const openCloseMenu = useCallback(function (ev: React.MouseEvent) {
    const currentTarget = ev.target as HTMLElement | null;
    if (currentTarget === menuContainer?.current) {
      menuContainer?.current?.classList.add('hidden');
    }
  }, []);

  const closeMenu = useCallback(function () {
    menuContainer?.current?.classList.add('hidden');
  }, []);

  const domainArray = user?.global_settings?.domain.split('.');

  return (
    <aside
      className="max-w-fit xl:max-w-[18rem] shrink-0 bg-gray-800/20 shadow-2xl shadow-gray-600/5 z-20 flex-1"
      ref={menuContainer}
      data-type="menu"
      onClick={openCloseMenu}
    >
      <div className="w-full max-w-[18rem] py-0 px-4 bg-white xl:bg-gray-50 sticky top-0 min-h-screen max-h-screen overflow-y-auto flex flex-col border-r rtl:border-r-none rtl:border-l border-gray-200 divide-y divide-gray-200">
        <div className="w-full hidden xl:block py-1.5">
          <Link
            to="/"
            className="shrink-0"
          >
            <Image
              src="/logo.png"
              alt="website logo"
              width="250"
              height="50"
              className="w-full max-w-[9rem] object-contain mx-auto object-center"
            />
          </Link>
          {/* <button
            className="shrink-0 text-gray-500 flex xl:hidden items-center justify-center"
            onClick={closeMenu}
          >
            <Icon
              icon="line-md:menu-to-close-transition"
              width={18}
            />
          </button> */}
        </div>
        {user.center ? (
          <a
            href={[...domainArray.slice(0, 1), 'c', ...domainArray.slice(1)].join('.') || '#'}
            target="_blank"
            rel="noopener noreferrer"
            className="hidden xl:flex items-center gap-4 py-4 group"
          >
            <Image
              className="w-10 h-10 rounded-full object-contain object-center shrink-0"
              width="40"
              height="40"
              src={user.center?.image}
            />
            <div className="space-y-0.5">
              <p className="text-sm font-semibold text-gray-800 line-clamp-1 group-hover:underline group-hover:text-primary">
                {user.center?.name || 'N/A'}
              </p>
              <p className="text-xs  text-gray-500 tabular-nums line-clamp-1">
                {user.center?.mobile || 'N/A'}
              </p>
            </div>
          </a>
        ) : null}

        <nav className="flex-1 py-4">
          <ul className="space-y-3">
            {_filterRoutes.map((route: RouterInterface, index: string | number) =>
              route.title === 'products' &&
              !user?.global_settings.enable_products_selling ? null : (
                <motion.li
                  key={index}
                  animate="visible"
                  initial="hidden"
                  variants={MoveToTop}
                >
                  <Link
                    to={route.path}
                    className={[
                      'w-full flex items-center gap-3 text-base rounded transition-all group',

                      route.path == '/' && location.pathname == route.path
                        ? 'text-black font-semibold'
                        : new RegExp(route.path, 'gi').test(location.pathname) && route.path !== '/'
                        ? 'text-black font-semibold'
                        : 'text-gray-500'
                    ].join(' ')}
                  >
                    <span
                      className={`shrink-0 w-10 h-10 rounded-full inline-flex items-center justify-center 
                  ${
                    route.path == '/' && location.pathname == route.path
                      ? 'bg-primary text-gray-600'
                      : new RegExp(route.path, 'gi').test(location.pathname) && route.path !== '/'
                      ? 'bg-primary text-gray-600'
                      : 'bg-gray-100 group-hover:bg-gray-200 group-hover:text-black'
                  }
                  `}
                    >
                      <Icon
                        icon={route.icon}
                        width={24}
                        height={24}
                      />
                    </span>
                    <span className="hidden xl:inline">{t(route.title)}</span>
                  </Link>
                </motion.li>
              )
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;

