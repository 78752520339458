import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store';

import Card from 'src/components/shared/Card';
import Image from 'src/components/shared/Image';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import AppBar from 'src/layouts/AppBar';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import UpdatePayments from 'src/components/bookins/payments/UpdatePayments';
import PrintPDF from 'src/components/invoices/PrintPDF';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';
import Dropdown from 'src/components/shared/Dropdown';
import ItemList from 'src/components/shared/ItemList';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function id() {
  const invoiceRef = useRef<HTMLDivElement | null>(null);
  const { id } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    getInvoice();
  }, []);

  function getInvoice() {
    setIsLoading(true);

    prepareRequest(
      {
        url: 'bookings/' + id
      },
      (data) => {
        let result = data?.result?.booking || {};
        setInvoice(() => result);
      }
    ).finally(() => setIsLoading(false));
  }

  function cancelInvoice() {
    setDisabled(true);
    prepareRequest(
      { method: 'post', url: 'bookings/update_status', data: { id, status: 'cancelled' } },
      (data) => {
        generateAlert(data.message, 'success');
        getInvoice();
      }
    ).finally(() => {
      setDisabled(false);
    });
  }

  const {
    formik: { values, handleChange, handleSubmit }
  } = useForm({
    initialValues: {
      invoice_date: invoice.invoice_date
    },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'bookings/update_invoice_date',
          method: 'post',
          data: {
            ...values,
            booking_id: id
          }
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          formikHelpers.resetForm();
          getInvoice();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="sticky top-0 z-20">
          <AppBar
            title={'#' + invoice.id}
            actions={
              <Dropdown
                button={
                  <Icon
                    icon="ion:menu"
                    width="18"
                  />
                }
                position="top-right"
              >
                <ul className="divide-y divide-gray-200 p-3">
                  <ItemList className="py-2">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          type="button"
                          className="btn-with-icon !p-0 !text-gray-600"
                        >
                          <Icon
                            icon="tabler:file-invoice"
                            width="18"
                          />
                          <span>{t('print-invoice')} (A4)</span>
                        </button>
                      )}
                      content={() => invoiceRef.current}
                      onAfterPrint={() => console.log('Invoice printed..')}
                    />
                  </ItemList>

                  <ItemList className="py-2">
                    <PrintPDF
                      item={invoice}
                      trigger={
                        <button
                          type="button"
                          className="btn-with-icon !p-0 !text-gray-600"
                        >
                          <Icon
                            icon="fluent-emoji-high-contrast:receipt"
                            width="18"
                          />
                          <span>
                            {t('print-invoice')} ({t('thermal')})
                          </span>
                        </button>
                      }
                    />
                  </ItemList>
                  {!(invoice.invoice_type == 'refunded' || invoice.status == 'cancelled') && (
                    <>
                      <ItemList className="py-2">
                        <button
                          className="btn-with-icon !text-gray-600 !p-0"
                          type="button"
                          onClick={() => setVisible(true)}
                        >
                          <Icon
                            icon="iconoir:edit"
                            width="18"
                            height="18"
                          />
                          <span>{t('update-invoice-date')}</span>
                        </button>
                      </ItemList>
                      <ItemList className="py-2">
                        <ButtonWithCallback
                          className="btn-with-icon !text-red-600 !p-0"
                          type="button"
                          callback={cancelInvoice}
                          disabled={disabled}
                          options={{
                            title: t('are-you-sure'),
                            text: t('confirmations.change-status'),
                            confirmButtonText: t('yes'),
                            cancelButtonText: t('close'),
                            showCancelButton: true,
                            icon: 'info'
                          }}
                        >
                          <Icon
                            icon="lets-icons:cancel"
                            width="18"
                            height="18"
                          />
                          <span>{t('cancel-invoice')}</span>
                        </ButtonWithCallback>
                      </ItemList>
                    </>
                  )}
                </ul>
              </Dropdown>
            }
          />
        </div>
        {!(invoice.invoice_type == 'refunded' || invoice.status == 'cancelled') && (
          <UpdatePayments
            id={id}
            item={invoice}
            refetch={getInvoice}
            btnTitle={t('update-payments')}
          />
        )}
        <div className="overflow-x-auto p-6">
          <div>
            <Card className="!p-0 max-w-fit mx-auto">
              <div
                className="space-y-4 p-6 w-[210mm] mx-auto"
                ref={invoiceRef}
                dir="rtl"
              >
                {user?.center?.image && (
                  <>
                    <Image
                      alt="center logo"
                      src={user?.center?.image || '/logo.png'}
                      width="150"
                      height="36"
                      className="mb-4 object-contain mx-auto"
                    />
                  </>
                )}
                <p className="text-sm text-center">
                  {user.center?.name} - {user.center?.alt_name}
                </p>
                <hr />

                <div className="grid grid-cols-2 gap-4 py-4">
                  <div className="space-y-2 text-sm">
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('name')}:</span>{' '}
                      <span>{user?.center?.name || '-'}</span>
                    </p>
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('address')}:</span>
                      <span>{user?.center?.address || '-'}</span>
                    </p>
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('email')}:</span>{' '}
                      <span>{user?.center?.email || '-'}</span>
                    </p>
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('mobile')}:</span>{' '}
                      <span>{user?.center?.mobile || '-'}</span>
                    </p>
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('vat-no')}:</span>{' '}
                      <span>{user?.center?.vat_no || '-'}</span>
                    </p>
                  </div>

                  <div className="space-y-2 text-sm">
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('reference-number')}:</span>{' '}
                      <span>#{invoice.id || '-'}</span>
                    </p>
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('status')}:</span>{' '}
                      <span>{t(`statuses.${invoice.status}`)}</span>
                    </p>

                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('created-date')}:</span>{' '}
                      <span>{moment(invoice.invoice_date).format('ll')}</span>
                    </p>
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('cashier')}:</span>{' '}
                      <span>{invoice?.cashier?.name || '-'}</span>
                    </p>
                  </div>
                  <div className="space-y-2 text-sm">
                    <p className="font-bold uppercase text-primary text-base">
                      {t('client-details')}
                    </p>

                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('name')}:</span>{' '}
                      <span>{invoice?.client?.name || '-'}</span>
                    </p>
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('email')}:</span>{' '}
                      <span>{invoice?.client?.email || '-'}</span>
                    </p>
                    <p className="grid grid-cols-2 gap-3">
                      <span className="font-bold">{t('mobile')}:</span>{' '}
                      <span>{invoice?.client?.mobile || '-'}</span>
                    </p>
                  </div>
                </div>

                <table className="styled-table border border-primary">
                  <thead className="bg-primary">
                    <tr>
                      <th className="!text-white">{t('name')}</th>

                      <th className="!text-white">{t('employee')}</th>
                      <th className="!text-white">{t('call-center')}</th>
                      <th className="!text-white">{t('status')}</th>
                      <th className="!text-white">{t('price')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoice?.items?.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          {item.code ? '[' + item.code + ']' : ''} - {item.name}
                          {!!item.items?.length && (
                            <p>[ {item.items?.map((e: any) => e.name).join(' , ')} ]</p>
                          )}
                        </td>

                        <td>{item.employee?.name || '-'}</td>
                        <td>{item.marketer?.name || '-'}</td>
                        <td>{t(`statuses.${item.status}`)}</td>
                        <td>{CurrencyFormatter(item.price || 0)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="divide-y divide-gray-200 bg-gray-50 border-t border-t-primary">
                    <tr>
                      <td colSpan={4}>{t('extra-discount')}</td>
                      <td>{CurrencyFormatter(invoice.extra_discount || 0)}</td>
                    </tr>
                    <tr>
                      <td colSpan={4}>{t('vat')}</td>
                      <td>{CurrencyFormatter(invoice.vat || 0)}</td>
                    </tr>
                    <tr>
                      <td colSpan={4}>{t('subtotal')}</td>
                      <td>{CurrencyFormatter(invoice.sub_total || 0)}</td>
                    </tr>
                    <tr>
                      <td colSpan={4}>{t('total')}</td>
                      <td>
                        {CurrencyFormatter(
                          eval(
                            `${invoice.invoice_type == 'refunded' ? '-' : ''}${invoice.total || 0}`
                          )
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>

                {invoice?.payments?.map((payment: any) => (
                  <>
                    <p className="font-bold uppercase text-primary text-base col-span-full">
                      {t('payments')}
                    </p>
                    <p className="grid grid-cols-2 gap-4 text-gray-600 text-sm font-medium col-span-full">
                      <span>{t(payment.payment_option)}</span>
                      <span>
                        {CurrencyFormatter(payment.amount || 0)}{' '}
                        {payment.ref_no && (
                          <>
                            <br /> {payment.ref_no}
                          </>
                        )}
                      </span>
                    </p>
                  </>
                ))}
                {invoice?.notes && (
                  <>
                    <p className="font-bold uppercase text-primary text-base col-span-full">
                      {t('notes')}
                    </p>
                    <p className=" text-gray-600 text-sm font-medium col-span-full">
                      {invoice?.notes}
                    </p>
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>

      <Modal
        title={t('update-invoice-date')}
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('date')}</p>
            <input
              type="date"
              name="invoice_date"
              id="invoice_date"
              className="form-input form-outline"
              value={values.invoice_date}
              onChange={handleChange}
              max={new Date().toISOString().substring(0, 10)}
            />
            <p className="form-error">{errors['invoice_date']}</p>
          </div>
          <button
            className="btn-with-icon bg-primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </form>
      </Modal>
    </>
  );
}

