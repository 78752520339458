import moment from 'moment';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import ExportOptions from 'src/components/shared/ExportOptions';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from 'src/components/shared/MiniDashboardSingleCard';
import Select from 'src/components/shared/Select';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import { RootState } from 'src/store';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import SearchBox from 'src/components/shared/SearchBox';
const { RangePicker } = DatePicker;

const BookingItems: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);

  const globalCards: ItemInterface[] = [
    {
      icon: 'uil:file-check-alt',
      color: '',
      title: t('completed-services'),
      key: 'completed',
      value: 0
    },
    {
      icon: 'ph:fire-bold',
      color: '',
      title: t('incomplete-services'),
      key: 'incompleted',
      value: 0
    },
    {
      icon: 'pajamas:issue-close',
      color: '',
      title: t('cancelled-services'),
      key: 'cancelled',
      value: 0
    }
  ];

  const [cards, setCards] = React.useState<any[]>(globalCards);
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [summary, setSummary] = useState<any>({});
  const [params, setParams] = useSearchParams({
    page: '1'
    // from: moment(new Date(new Date().setDate(new Date().getDate() - 29))).format('YYYY-MM-DD'),
    // to: moment(new Date()).format('YYYY-MM-DD')
  });

  const [pagination, setPagination] = React.useState<any>({
    page: 1
  });

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()]).finally(() => {
        setIsLoading(false);
      });
      rerender = false;
    }
  }, [params]);

  function GetItems() {
    setIsLoading(true);
    setIsUpdating(true);
    const searchParams = new URLSearchParams(params);

    const statuses = searchParams.get('status')?.length
      ? searchParams.get('status')?.split(',')
      : [];
    searchParams.delete('status');

    const client_ids = searchParams.get('client_id')?.length
      ? searchParams.get('client_id')?.split(',')
      : [];
    searchParams.delete('client_id');

    const employee_ids = searchParams.get('employee_id')?.length
      ? searchParams.get('employee_id')?.split(',')
      : [];
    searchParams.delete('employee_id');

    const item_ids = searchParams.get('item_id')?.length
      ? searchParams.get('item_id')?.split(',')
      : [];
    searchParams.delete('item_id');

    statuses?.forEach((status: string, index: number) => {
      searchParams.set('status[' + index + ']', status);
    });

    client_ids?.forEach((item: string, index: number) => {
      searchParams.set('client_id[' + index + ']', item);
    });

    employee_ids?.forEach((item: string, index: number) => {
      searchParams.set('employee_id[' + index + ']', item);
    });

    item_ids?.forEach((item: string, index: number) => {
      searchParams.set('item_id[' + index + ']', item);
    });

    prepareRequest(
      {
        url: 'bookings/items',
        params: searchParams
      },
      (data) => {
        const { data: items, pagination: responsePaginate } = data?.result?.booking_items;
        const summary = data?.result?.summary;
        const mapCards = cards.map((e) => {
          const item = summary[e.key];

          return {
            ...e,
            value: item?.count || '-'
          };
        });
        setCards(mapCards);
        setSummary(data?.result?.summary);
        setPagination(() => responsePaginate);

        setResponses(items);
      }
    ).finally(() => {
      setIsLoading(false);
      setIsUpdating(false);
    });
  }

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>#</th>
              <th>{t('name')}</th>
              <th>{t('client')}</th>
              <th>{t('expert')}</th>

              <th>{t('price')}</th>
              <th>{t('status')}</th>
              <th>{t('items-date')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link
                        className="text-blue-600"
                        to={'/bookings/' + item.booking?.id}
                      >
                        {item.booking?.id || '-'}
                      </Link>
                    </td>
                    <td>
                      <span>{item.code}</span>
                      <br />
                      <span>{item?.name || '-'}</span>
                    </td>
                    <td>
                      {item.booking?.client?.name || '-'}
                      <br />
                      <span>{item.booking?.client?.mobile || '-'}</span>
                    </td>
                    <td>{item.employee?.name || '-'}</td>

                    <td>{CurrencyFormatter(item?.price || 0)}</td>
                    <td>{t('statuses.' + item.status)}</td>
                    <td>
                      <SharedTime
                        date={item.booking_time}
                        format="DD-MM-yyyy hh:mmA"
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions excelPathname="bookings/items/export_excel" />
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setParams((param) => {
              param.set('search_key', (e.target as any).value);

              param.set('page', '1');
              return param;
            });
          }
        }}
        onNextClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page + 1 : 1).toString()
            );

            return param;
          });
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page - 1 : 1).toString()
            );

            return param;
          });
        }}
        loading={isLoading || isUpdating}
        hasSearch={!!user.summary?.services_count}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">
        <FilterComponent isLoad={isUpdating || isLoading} />

        <div className="grid grid-wrapper gap-3">
          {cards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>

        {MEMO_TABLE}
      </div>
    </React.Fragment>
  );
};

function FilterComponent({ isLoad = false }) {
  let render = true;
  const { t } = useTranslation();

  const [items, setItems] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [employees, setEmployees] = useState<any[]>([]);
  const [params, setParams] = useSearchParams();

  const filterBookings = [
    {
      label: t('all'),
      key: 'all'
    },

    {
      label: t('completed-services'),
      key: 'completed'
    },
    {
      label: t('incomplete-services'),
      key: 'incomplete'
    },
    {
      label: t('cancelled-services'),
      key: 'cancelled'
    }
  ];

  useEffect(() => {
    if (!render) return;
    Promise.all([getItems(), getClients(), getEmployee()]);
  }, []);

  const getItems = (search_key?: string) =>
    prepareRequest({ url: 'items', params: { is_active: 1, page: 1, search_key } }, (data) => {
      setItems(data.result?.items?.data);
    });

  const getClients = (search_key?: string) =>
    prepareRequest(
      {
        url: 'users',
        params: { is_active: 1, user_type: 'client', search_key, page: 1 }
      },
      (data) => {
        const users = data.result?.users?.data || [];
        setClients(() =>
          users.map((e: any) => ({
            ...e,
            name: [e.name, e.mobile].join(' - ')
          }))
        );
      }
    );

  const getEmployee = (search_key?: string) =>
    prepareRequest(
      {
        url: 'users',
        params: { is_active: 1, user_type: 'employee', search_key, page: 1 }
      },
      (data) => {
        const users = data.result?.users?.data || [];
        setEmployees(users);
      }
    );

  function getTabsData(key: string) {
    let result: any = {};
    if (key === 'completed') {
      result = {
        status: ['completed']
      };
    } else if (key === 'incomplete') {
      result = {
        status: ['pending', 'running']
      };
    } else if (key === 'cancelled') {
      result = {
        status: ['cancelled']
      };
    }

    setParams((param) => {
      // result.status?.map((status: string, index: number) => {
      if (key === 'all') {
        param.delete('status');
      } else {
        param.set('status', result.status);
      }
      //   param.set('status[' + index + ']', status);
      // });
      param.set('page', '1');
      return param;
    });
  }

  return (
    <div className="grid grid-wrapper gap-3">
      <div className="form-group">
        <p className="form-label">{t('type')}</p>
        <select
          className="form-select form-outline"
          name="type"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            getTabsData((e.target as any).value);
          }}
          defaultValue=""
          value={params.get('type') as string}
        >
          {filterBookings.map((option, index) => (
            <option
              value={option.key}
              key={index}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <p className="form-label">{t('client')}</p>
        <Select
          value={params.get('client_id')?.split(',') as any[]}
          options={clients}
          optionTxt="name"
          optionValue="id"
          key={Math.random()}
          type="multi"
          onSelect={(value) => {
            setParams((param) => {
              param.set('client_id', Array.isArray(value) ? value.join(',') : '');
              param.set('page', '1');
              return param;
            });
          }}
          onSearchChange={(ev) => getClients(ev.target.value)}
        />
      </div>

      <div className="form-group">
        <p className="form-label">{t('expert')}</p>
        <Select
          value={params.get('employee_id')?.split(',') as any[]}
          options={employees}
          optionTxt="name"
          optionValue="id"
          key={Math.random()}
          type="multi"
          onSelect={(value) => {
            setParams((param) => {
              param.set('employee_id', Array.isArray(value) ? value.join(',') : '');
              param.set('page', '1');
              return param;
            });
          }}
          onSearchChange={(ev) => getEmployee(ev.target.value)}
        />
      </div>

      <div className="form-group">
        <p className="form-label">{t('services')}</p>
        <Select
          value={params.get('item_id')?.split(',') as any[]}
          options={items}
          optionTxt="name"
          optionValue="id"
          key={Math.random()}
          type="multi"
          onSelect={(value) => {
            setParams((param) => {
              param.set('item_id', Array.isArray(value) ? value.join(',') : '');
              param.set('page', '1');
              return param;
            });
          }}
          onSearchChange={(ev) => getItems(ev.target.value)}
        />
      </div>

      <div className="custom-date-picker space-y-2">
        <p className="form-label">{t('date')}</p>

        <RangePicker
          defaultValue={[
            dayjs(params.get('from') || moment(new Date()).format('YYYY-MM-DD'), 'YYYY-MM-DD'),
            dayjs(params.get('to') || moment(new Date()).format('YYYY-MM-DD'), 'YYYY-MM-DD')
          ]}
          className="text-center"
          onChange={(date, dateString) => {
            setParams((param) => {
              if (!dateString[0] || !dateString[1]) {
                param.delete('from');
                param.delete('to');
              } else {
                param.set('from', dateString[0]);
                param.set('to', dateString[1]);
              }

              param.set('page', '1');
              return param;
            });
          }}
        />
      </div>
    </div>
  );
}
export default BookingItems;

