import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import useForm from 'src/hooks/useForm';
import Mobile from '../Mobile';
import Password from '../Password';

export default function UpdateForm({
  closeModal,
  reFetching,
  schema,
  user_type
}: {
  closeModal: any;
  reFetching: any;
  schema: any;
  user_type?: UserType;
}): JSX.Element {
  const [initialValues] = useState(schema);
  const [errors, setErrors] = useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    GetRoles();
  }, []);

  const GetRoles = useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('roles', { params: { is_active: 1 } });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitHandler = useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);
      const { mobile, ...others } = values;
      const { data } = await axiosInstance.post('users/update', {
        mobile: mobile?.replace(/\s/gi, ''),
        ...others
      });
      await reFetching();
      helper.resetForm();
      closeModal(false);
      generateAlert(data.message, 'success');
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          generateAlert('Something went wrong while creating..', 'error');
        }
        return;
      }
      generateAlert('Something went wrong while creating..', 'error');
      console.log(error);
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { handleChange, values, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.name}
          name="name"
          onChange={handleChange}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">{t('mobile')}</label>
        <Mobile
          className="form-input form-outline"
          value={values.mobile}
          name="mobile"
          onChange={handleChange}
          dir="ltr"
        />
        {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('email')}</label>
        <input
          type="email"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.email}
          name="email"
          onChange={handleChange}
        />
        {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
      </div>

      {!['employee', 'client', 'supplier'].includes(values.user_type) ? (
        <div className="form-group">
          <label className="form-label">{t('password')}</label>
          <Password
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={values.password}
            name="password"
            onChange={handleChange}
          />
          {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
        </div>
      ) : null}
      {/* {['marketer', 'client'].includes(values.user_type) ? (
        <div className="form-group">
          <label className="form-label">{t('code')}</label>
          <input
            type="text"
            autoComplete="off"
            className="form-input form-outline"
            value={values.code}
            name="code"
            onChange={handleChange}
            placeholder="0000"
          />
          {errors?.code ? <span className="form-error">{errors?.code}</span> : null}
        </div>
      ) : null} */}
      {['client'].includes(values.user_type) ? (
        <div className="form-group">
          <label className="form-label">{t('address')}</label>
          <input
            type="text"
            autoComplete="off"
            className="form-input form-outline"
            value={values.address}
            name="address"
            onChange={handleChange}
          />
          {errors?.address ? <span className="form-error">{errors?.address}</span> : null}
        </div>
      ) : null}
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {['marketer', 'cashier'].includes(values.user_type) ? (
          <div className="form-group">
            <label className="form-label">{t('discount')}</label>
            <input
              type="number"
              autoComplete="off"
              className="form-input form-outline"
              value={values.discount}
              name="discount"
              onChange={handleChange}
              max="100"
              step="any"
              placeholder="0.00 %"
            />
            {errors?.discount ? <span className="form-error">{errors?.discount}</span> : null}
          </div>
        ) : null}

        {['marketer', 'cashier', 'employee'].includes(values.user_type) ? (
          <>
            <div className="form-group">
              <label className="form-label">{t('salary')}</label>
              <input
                type="number"
                autoComplete="off"
                placeholder="0.00 SAR"
                className="form-input form-outline"
                value={values.salary}
                name="salary"
                onChange={handleChange}
                step="any"
              />
              {errors?.salary ? <span className="form-error">{errors?.salary}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('target')}</label>
              <input
                type="number"
                autoComplete="off"
                placeholder="0.00 SAR"
                className="form-input form-outline"
                value={values.target_amount}
                name="target"
                onChange={handleChange}
                step="any"
              />
              {errors?.target_amount ? (
                <span className="form-error">{errors?.target_amount}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('commission')}</label>
              <input
                type="number"
                autoComplete="off"
                placeholder="%00.00"
                className="form-input form-outline"
                value={values.commission}
                name="commission"
                onChange={handleChange}
                step="any"
              />
              {errors?.commission ? <span className="form-error">{errors?.commission}</span> : null}
            </div>
          </>
        ) : null}
      </div> */}
      {/* {values.user_type === 'admin' ? (
        <div className="form-group">
          <label className="form-label">{t('role')}</label>
          <Select
            type={'single'}
            options={roles}
            value={values.role_id}
            onSelect={function (value: any): void {
              setFieldValue('role_id', value);
            }}
            optionTxt={'name'}
            optionValue={'id'}
          />
        </div>
      ) : null} */}

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
}


