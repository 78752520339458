import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import Image from 'src/components/shared/Image';
import { RootState } from 'src/store';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from '../../components/shared/MiniDashboardSingleCard';

const Settings: FC = () => {
  const { t } = useTranslation();
  const cards: ItemInterface[] = [
    {
      icon: 'mdi:account-tie-outline',
      color: 'text-gray-500',
      value: t('employees'),
      url: '/settings/employees'
    },
    {
      icon: 'mi:users',
      color: 'text-gray-500',
      value: t('clients'),
      url: '/settings/clients'
    },

    {
      icon: 'fe:layer',
      color: 'text-gray-500',
      value: t('categories'),
      url: '/settings/categories'
    },
    {
      icon: 'iconoir:grid-add',
      color: 'text-gray-500',
      value: t('services'),
      url: '/settings/services'
    },
    {
      icon: 'solar:tag-outline',
      color: 'text-gray-500',
      value: t('packages-offers'),
      url: '/settings/packages-offers'
    },
    {
      icon: 'fluent-mdl2:product-variant',
      color: 'text-gray-500',
      value: t('products'),
      url: '/settings/products'
    }
  ];

  const inventoryCards: ItemInterface[] = [
    {
      icon: 'fluent-mdl2:product-variant',
      color: 'text-gray-500',
      value: t('stock-counts'),
      url: '/settings/inventory/stocktaking'
    },
    {
      icon: 'solar:cart-linear',
      color: 'text-gray-500',
      value: t('purchases'),
      url: '/settings/inventory/purchase-orders'
    },
    {
      icon: 'solar:settings-minimalistic-linear',
      color: 'text-gray-500',
      value: t('wastages-management'),
      url: '/settings/wastages-management'
    },
    {
      icon: 'solar:trash-bin-minimalistic-linear',
      color: 'text-gray-500',
      value: t('wastages'),
      url: '/settings/wastages'
    },
    {
      icon: 'clarity:process-on-vm-line',
      color: 'text-gray-500',
      value: t('processing'),
      url: '/settings/processing'
    }
  ];
  return (
    <Fragment>
      <div className="p-6 space-y-4">
        <p className="text-sm font-medium text-gray-500">{t('settings')}</p>
        <div className="grid grid-wrapper gap-3">
          {cards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

function CenterConfiguration() {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  return (
    <Card>
      <Link
        to={
          ['owner', 'admin'].includes(user.user_type) ? '/settings/centers/' + user.center?.id : '#'
        }
        className="hidden grid-cols-1 sm:grid-cols-2 gap-3"
      >
        <div className="col-span-full">
          <Image
            className="w-12 h-12 rounded-full object-cover"
            src={user.center?.logo}
          />
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('name')}</p>
          <p className="font-semibold text-gray-700">{user.center?.name || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('email')}</p>
          <p className="font-semibold text-gray-700">{user.center?.email || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('mobile')}</p>
          <p className="font-semibold text-gray-700">{user.center?.mobile || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('address')}</p>
          <p className="font-semibold text-gray-700">{user.center?.address || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('vat-no')}</p>
          <p className="font-semibold text-gray-700">{user.center?.vat_no || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('cr-no')}</p>
          <p className="font-semibold text-gray-700">{user.center?.cr_no || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('bank-name')}</p>
          <p className="font-semibold text-gray-700">{user.center?.bank_name || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">IBAN</p>
          <p className="font-semibold text-gray-700">{user.center?.iban || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('accept-partial-payments')}</p>
          <p className="font-semibold text-gray-700">
            {user.center?.accept_partial_payments ? t('yes') : t('no')}
          </p>
        </div>
      </Link>
    </Card>
  );
}

export default Settings;

