import { Popover } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import ItemList from './ItemList';
import { useSearchParams } from 'react-router-dom';

export default function ExportOptions({ excelPathname }: { excelPathname?: string }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [params] = useSearchParams({
    page: '1'
  });

  const exportExcel = () => {
    if (!excelPathname)
      return generateAlert("This feature isn't available at that moment, try again later.", 'info');
    setDisabled(true);
    prepareRequest({ url: excelPathname, params }, (data) => {
      downloadFile('xlsx', data);
    }).finally(() => setDisabled(false));
  };

  return (
    <>
      <Popover className="relative">
        <Popover.Button className="btn-with-icon outline-btn !text-gray-600 shrink-0">
          <Icon
            icon="uil:file-export"
            width={18}
          />
          <span>{t('export')}</span>
        </Popover.Button>

        <Popover.Panel className="absolute z-10 top-full mt-2 right-0 rtl:right-auto rtl:left-0 ring-1 ring-slate-200 bg-white rounded-lg w-max">
          <ul className="divide-y divide-slate-200 p-3">
            <ItemList className="py-2">
              <button
                type="button"
                className="btn-with-icon !text-gray-600 !p-0"
                disabled={disabled}
                onClick={exportExcel}
              >
                <Icon
                  icon="bi:filetype-xlsx"
                  width={18}
                />
                <span>{t('exports.xlsx')}</span>
              </button>
            </ItemList>
          </ul>
        </Popover.Panel>
      </Popover>
    </>
  );
}

export function downloadFile(type: string, data: any) {
  const blob = new Blob([data]);
  const link = document.createElement('a');
  const fileURL = URL.createObjectURL(blob);
  link.href = fileURL;
  link.download = new Date().getTime() + '.' + type;
  link.click();
  URL.revokeObjectURL(fileURL);
}

