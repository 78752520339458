import { Icon } from '@iconify/react';
import React, { useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Dropdown from 'src/components/shared/Dropdown';
import ItemList from 'src/components/shared/ItemList';
import axiosInstance from 'src/helper/AxiosInstance';
import { setCenter, setCenters } from 'src/reducers/globalResponse';
import { titles } from '../helper/PageTitles';
import ProfileNavigation from './ProfileNavigation';
import SideBar from './SideBar';

const HeaderLayout: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(setCenter(localStorage.getItem('@center')));
    axiosInstance.get('centers', { params: { is_active: 1 } }).then(({ data }) => {
      dispatch(setCenters(data.result?.centers || []));
    });
  }, []);

  const hasMenu = useMemo(() => {
    const pathname = location.pathname;
    const names = [
      '/bookings/((?!items).)+',
      '/settings/inventory/purchase-orders/',
      '/pos/new',
      '/settings/journal-entries/',
      '/settings/inventory/stocktaking/',
      '/invoices/',
      '/account/setup'
    ];
    const regex = new RegExp('^(' + names.join('|') + ')', 'gm');

    return !regex.test(pathname);
  }, [location.pathname]);

  const hasBack = useMemo(() => {
    const pathname = location.pathname;
    const names = ['settings/+'];
    const regex = new RegExp('(' + names.join('|') + ')', 'gi');

    return regex.test(pathname);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div
        className="flex min-h-screen bg-white"
        data-type="body"
      >
        {hasMenu ? (
          <>
            <SideBar />
            <main
              className="flex-1 shrink-0 self-stretch min-h-screen flex flex-col"
              data-type="content"
            >
              <header className="w-full bg-white sticky top-0 z-10 border-b border-b-gray-200">
                <div className="flex items-center gap-4 py-4 px-6">
                  {hasBack ? (
                    <button
                      className="text-gray-600"
                      onClick={() => navigate(-1)}
                    >
                      <Icon
                        icon="humbleicons:arrow-left"
                        width="20"
                        className="block rtl:hidden"
                      />
                      <Icon
                        icon="humbleicons:arrow-right"
                        width="20"
                        className="hidden rtl:block"
                      />
                    </button>
                  ) : null}
                  <div className="flex-1 grid">
                    <p
                      className="text-base font-semibold line-clamp-1 text-gray-800"
                      data-title={location.pathname}
                    >
                      {t(titles[location.pathname]) || ''}
                    </p>
                  </div>
                  <div>
                    <ProfileNavigation />
                  </div>
                </div>
              </header>
              <div className="flex-1">{children}</div>
            </main>
          </>
        ) : (
          <>
            <div className="flex-1 flex-col">
              <div className="flex-1 w-full">{children}</div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

interface IProps {
  path: string;
  icon: string;
  alert?: boolean;
}

function NavBarButtonLink({ path, icon, alert = false }: IProps) {
  return (
    <Link
      to={path || '#'}
      className="w-10 h-10 rounded-full bg-gray-100 text-gray-600 flex items-center justify-center"
    >
      {alert && (
        <span className="w-2 h-2 rounded-full bg-red-500 absolute top-0 right-0 rtl:right-auto rtl:left-0 animate-ping"></span>
      )}
      <Icon
        icon={icon}
        width="24"
        height="24"
      />
    </Link>
  );
}

export function ChangeLanguage() {
  const { i18n, t } = useTranslation();
  const languages = ['ar', 'en'];

  const onlanguagechange = (lang: string) => {
    localStorage.setItem('@lang', lang);
    window.location.reload();
  };
  return (
    <Dropdown
      position="top-right"
      button={
        <>
          <Icon
            icon="heroicons:language-20-solid"
            width="22"
            height="22"
          />
          {/* <span>{t('languages.' + i18n.language)}</span> */}
        </>
      }
    >
      <ul className="divide-y divide-gray-200 p-3">
        {languages.map((lang: string, index: number) => (
          <ItemList
            key={index}
            className="py-2"
          >
            <a
              href="javascript:void(0)"
              className="btn-with-icon !p-0 !text-gray-600 font-semibold"
              onClick={() => onlanguagechange(lang)}
            >
              {t('languages.' + lang)}
            </a>
          </ItemList>
        ))}
      </ul>
    </Dropdown>
  );
}
export default HeaderLayout;

